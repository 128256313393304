/**
 * Scripting for the domain filter UI.
 */

const domainFilters = document.getElementById('domain-filters');
const counterCache = {};

/**
 * Update the summary shown next to each Series title.
 *
 * @param {Event} e - The event causing the summary to be updated.
 */
function updateSummary(e) {
    const series = this;

    if ('INPUT' !== e.target.tagName) {
        return;
    }

    getCounter(series).firstElementChild.innerText = series.querySelectorAll('.panel-body input:not(:checked)').length;
}

/**
 * When a user has chosen to hide an entire Series, disable the topic-specific inputs.
 *
 * @param {Event} e - The change event.
 */
function toggleSeriesState(e) {
    const series = this;
    const seriesToggle = e.target;

    if ('INPUT' !== seriesToggle.tagName || 'filters[series][]' !== seriesToggle.name) {
        return;
    }

    const topics = series.querySelectorAll('.panel-body input');
    const counter = getCounter(series);

    if (seriesToggle.checked) {
        topics.forEach(function (topic) {
            topic.setAttribute('disabled', true);
        });

        counter.firstElementChild.innerText = 0;

    } else {
        topics.forEach(function (topic) {
            topic.removeAttribute('disabled');
        });

        counter.firstElementChild.innerText = series.querySelectorAll('.panel-body input:not(:checked)').length;
    }
}

/**
 * Retrieve a counter from the cache and create a new entry if it doesn't yet exist.
 *
 * @param {Element} series
 *
 * @return {Element}
 */
function getCounter(series) {
    if (! counterCache.hasOwnProperty(series.dataset.seriesId)) {
        let counter = document.createElement('span');
        let visibleCount = document.createElement('span');
        let totalCount = document.createElement('span');

        counter.classList.add('domain-filter-counter');
        visibleCount.classList.add('visible-topics');
        visibleCount.innerText = series.querySelectorAll('.panel-body input:not(:checked)').length;
        totalCount.classList.add('total-topics');
        totalCount.innerText = `${series.querySelectorAll('.panel-body input').length} videos`;

        counter.appendChild(visibleCount);
        counter.appendChild(totalCount);
        series.querySelector('.panel-title a').appendChild(counter);

        counterCache[series.dataset.seriesId] = counter;
    }

    return counterCache[series.dataset.seriesId];
}

if (domainFilters) {
    domainFilters.querySelectorAll('.panel').forEach(function (series) {
        getCounter(series);

        series.addEventListener('change', updateSummary);
        series.addEventListener('change', toggleSeriesState);

        // Set the initial state for disabled inputs.
        const evt = new Event('change', {bubbles: true});
        series.querySelector('input[name="filters[series][]"]').dispatchEvent(evt);
    });
}

