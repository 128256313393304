/**
 * Scripting for coupon validation.
 */

(function () {
    'use strict';

    const couponField = document.getElementById('coupon');
    const couponInfo  = document.getElementById('coupon-details');

    if (! couponField || ! couponInfo) {
        return;
    }

    // Only show the coupon form after clicking .coupon-expand.
    document.querySelector('.coupon-expand').addEventListener('click', e => {
        e.preventDefault();

        document.querySelector('.coupon-field').removeAttribute('hidden');
        couponField.focus();
    });

    // After #coupon loses focus, send an ajax request to validate (and pre-cache) it.
    couponField.addEventListener('blur', function () {
        window.axios.get(`/api/validate-coupon?coupon=${this.value}`)
            .then(response => {
                couponInfo.innerHTML = `<strong>${response.data.data.name}</strong>`;
                if (response.data.data.description) {
                    couponInfo.innerHTML += `<p>${response.data.data.description}</p>`;
                }
                couponInfo.classList.add('alert-success');
                couponInfo.removeAttribute('hidden');
            }).catch(err => {
                couponInfo.setAttribute('hidden', true);
            });
    });
})();
