/**
 * Form scripting.
 */

const Choices = require('choices.js');
const validate = require("validate.js");

/**
 * Use Choices for <select multiple> elements.
 *
 * @link https://github.com/jshjohnson/Choices
 */
document.querySelectorAll('select[multiple]').forEach(function (el) {
    new Choices(el, {
        removeItemButton: true,
        noChoicesText: el.dataset.noChoicesText || 'No choices are available.',
    });
});

/**
 * Show a confirmation message if an attribute with a data-confirmation-message attribute is clicked.
 */
document.addEventListener('click', function (e) {
    if (! e.target.dataset.confirmationMessage) {
        return;
    }

    if (! confirm(e.target.dataset.confirmationMessage)) {
        e.preventDefault();
    }
});

/**
 * Polyfill [disabled] behavior for links.
 */
document.addEventListener('click', function (e) {
    if ('A' === e.target.tagName && e.target.hasAttribute('disabled')) {
        e.preventDefault();
    }
});

/**
 * Validate a form using validate.js before submitting.
 *
 * @param {Event} e - The form submit event.
 */
function validateForm(e) {
    const form = this;
    const data = validate.collectFormValues(form);
    const validationErrors = document.getElementById('validation-errors');
    const existingErrors = validationErrors.firstElementChild || document.createElement('ul');
    let rules = {};

    e.preventDefault();

    // Clear out any existing validation errors.
    validationErrors.setAttribute('hidden', true);

    while (existingErrors.firstElementChild) {
        existingErrors.removeChild(existingErrors.firstElementChild);
    }

    // Build the list of validation rules, based on data-validation-rules.
    Object.keys(data).forEach(function (field) {
        rules[field] = JSON.parse(form[field].dataset.validationRules) || false;
    });

    let errors = validate(data, rules, {
        format: 'flat',
    });

    if (errors) {
        errors.forEach(function (error) {
            let msg = document.createElement('li');

            msg.innerText = error;

            validationErrors.firstElementChild.appendChild(msg);
        });

        validationErrors.removeAttribute('hidden');

    // Don't auto-submit a Recurly form.
    } else if (! form.querySelector('[data-recurly="token"]')) {
        form.submit();
    }
}

document.querySelectorAll('form[novalidate]').forEach(function (form) {

    // Add "data-ignored" to elements without data-validation-rules.
    Object.values(form.elements).forEach(function (el) {
        if (! el.hasAttribute('data-validation-rules')) {
            el.setAttribute('data-ignored', true);
        }
    });

    // Queue up client-side form validation.
    form.addEventListener('submit', validateForm);
});
