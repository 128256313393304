/**
 * Table sorting and behavior.
 */

/**
 * Clicking an element with data-orderby will update the query string to sort results.
 */
document.addEventListener('click', function (e) {
    if (! e.target.dataset.orderby) {
        return;
    }

    let params = new URLSearchParams(window.location.search);

    // We're already sorting by this value, so invert it.
    if (e.target.dataset.orderby === params.get('orderby')) {
        params.set('order', ('desc' === params.get('order') ? 'asc' : 'desc'));

    } else {
        params.set('orderby', e.target.dataset.orderby);
        params.set('order', 'asc');
    }

    window.location.search = params.toString();
});

/**
 * Clicking a .refresh-link will refresh the page.
 */
document.addEventListener('click', function (e) {
    if (! e.target.classList.contains('refresh-button')) {
        return;
    }

    return window.location.reload();
});
