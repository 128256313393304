/**
 * jQuery UI sorting.
 */

require('jquery-ui-sortable-npm');

const $ = jQuery;

/**
 * Enable topics to be sorted for the given series.
 */
$('.list-group-sortable').sortable({
    handle: '.glyphicon',
});
