/**
 * General application scripting.
 */

require('./bootstrap');
require('./modules/apiKey.js');
require('./modules/clipboard.js');
require('./modules/coupons.js');
require('./modules/domainFilters.js');
require('./modules/forms.js');
require('./modules/sorting.js');
require('./modules/tables.js');
require('./modules/tooltips.js');
require('./modules/tinymce.js');
