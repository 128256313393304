/**
 * TinyMCE editor configuration.
 */

import tinymce from 'tinymce/tinymce';

// Load theme and plugins.
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';

// Initialize TinyMCE.
tinymce.init({
    selector: 'textarea.editor',
    branding: false,
    menubar: false,
    plugins: ['autoresize', 'code', 'link', 'lists', 'paste'],
    toolbar: 'bold italic link | bullist numlist | undo redo | removeformat | code',
    skin: false,
    content_css: '/css/app.css',
    content_style: '.mce-content-body { padding: 10px !important; }',
    statusbar: false,
});
