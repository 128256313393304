/**
 * Scripting for the API key edit screen.
 */

const domainTable = document.getElementById('domain-table');

/**
 * Adjust row classes based on a domain's whitelist status.
 */
function toggleWhitelist(e) {
    if ('SELECT' !== e.target.tagName) {
        return;
    }

    const select = e.target;
    const row = select.parentElement.parentElement.classList;

    row.toggle('domain-blacklisted', '0' === e.target.value);
}

if (domainTable) {
    domainTable.addEventListener('change', toggleWhitelist);
}
